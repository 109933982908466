@import "../../../styles/base/mixins.scss";

.products_detail_container {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    display: flex;
    z-index: 6000;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .product_detail {
        position: relative;
        background-color: #e4e4db;
        border-radius: 0.625rem;
        width: 96%;
        max-width: 409px;
        margin: auto;
        display: flex;
        flex-direction: column;
        z-index: 9000;
        overflow: hidden;

        @include breakpoint(md) {
            width: 54%;
            max-width: 450px;
        }

        .close_btn {
            @include close_button;
        }

        .product_image {
            width: 100%;
            height: auto;
            max-width: 507px;
            max-height: 290px;
            border-radius: 0.625rem;
            background: center;
            object-fit: cover;

            /* @include breakpoint(md) {
                height: auto;
                max-height: 290px;
            } */
        }

        .product_detail_content {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .product_detail_title {
                .detail_title {
                    font-weight: 500;
                    margin-bottom: 0.4rem;
                }

                .detail_desc {
                    font-weight: 200;
                    font-family: "Manrope", sans-serif;
                    font-size: 90%;
                }
            }

            .ingredients {
                @include ingredients;
            }

            .variant_container {
                display: flex;
                flex-direction: column;
                gap: 0.7rem;

                .options_container {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;

                    .option_btn {
                        cursor: pointer;
                        padding: 0.8rem 7%;
                        background-color: #fff;
                        border: none;
                        color: #000;
                        border-radius: 0.625rem;
                        width: 100%;
                        font-size: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        gap: 0.5rem;

                        &.active {
                            background-color: #3cc6b1;
                            color: #fff;
                            cursor: default;
                        }

                        .price {
                            font-size: 80%;
                            font-weight: 500;
                        }
                    }
                }

                .options_extraGroups_container {
                    display: flex;
                    flex-direction: column;
                    gap: 0.7rem;
                    font-size: 110%;

                    .extraGroup {
                        display: flex;
                        flex-direction: column;
                    }

                    .extraGroups_name {
                        padding: 0.3rem;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 0.4rem;

                        .txt {
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;

                            .max_limit {
                                font-size: 60%;
                            }
                        }
                    }

                    .extras_container {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                        padding: 0 0.5rem;

                        .extras {
                            display: flex;
                            justify-content: space-between;
                            flex-direction: row;
                            gap: 0.5rem;
                            align-items: center;
                            font-weight: 300;
                            cursor: pointer;

                            .extraName {
                                margin-left: 5px;
                            }

                            .price {
                                font-size: 80%;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .product_note {
                font-size: 110%;
                resize: none;
                border: 1px solid #c5c5b0;
                background: #dbdbd1;
                border-radius: 1rem;
                padding: 0.5rem;
                outline: none;

                &:focus {
                    outline: none;
                }
            }

            .detail_payment {
                display: flex;
                flex-direction: row;
                gap: 0.7rem;
                padding-top: 0;

                .detail_payment_content {
                    display: flex;
                    flex-direction: row;
                    gap: 0.6rem;
                    align-items: center;

                    .payment_content_btn {
                        cursor: pointer;
                        padding: 0.4rem;
                        min-width: 1.3rem;
                        border-radius: 1rem;
                        background-color: #3cc6b1;
                        border: 1px solid #3cc6b1;
                        color: #fff;
                    }
                }

                .detail_payment_btn {
                    cursor: pointer;
                    padding: 0.8rem 7%;
                    background-color: #3cc6b1;
                    border: none;
                    color: #fff;
                    border-radius: 0.625rem;
                    width: 100%;
                }
            }
        }
    }
}
