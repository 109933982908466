.navbar {
    width: 100%;
    padding: 1.1rem 1rem;
    background-color: transparent;
    margin-top: -4.3rem;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 3000;

    @include breakpoint(md) {
        margin-top: -4.65rem;
    }

    &.colorActive {
        background-color: #f5f3ed;
        border-bottom: 1px solid #ddd;
    }

    .nav_items {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }

    .nav_item_badge {
        .ant-badge-count {
            display : none;
            @media (max-width: 768px) {
                display: block; // Show the div only on mobile devices
            }
        }
    }

    .nav_item {
        color: #000;
        background: #fff;
        font-size: 0.95rem;
        font-weight: 300;
        border: none;
        border-radius: 0.5rem;
        padding: 0.625rem 1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.3rem;

        .item_txt {
            display: none;

            @include breakpoint(md) {
                display: inline;
            }
        }

        &.active {
            background: #3cc6b1;
            cursor: default;
        }

        &.color {
            background: #3cc6b1;
        }
    }

    .clock {
        cursor: default;

        .item_txt {
            display: inline;
        }
    }

    .basket {
        background-color: #f5f3ed;
        padding: 2rem;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        position: fixed;
        z-index: 1000;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 3rem;
        min-width: 320px;
        max-width: 400px;
        width: 100%;
        overflow: auto;

        @include breakpoint(md) {
            min-width: 400px;
        }

        &.active {
            display: none;
        }

        .close_icon {
            font-size: 130%;
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
        }

        .basket_container {
            display: flex;
            flex-direction: column;
            gap: 1.4rem;
            z-index: 7000;

            .basket_title {
                text-align: center;
                font-weight: 400;
            }

            .basket_product {
                padding: 0.7rem;
                box-shadow:
                    rgba(0, 0, 0, 0.1) 0 0 5px 0,
                    rgba(0, 0, 0, 0.1) 0 0 1px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                gap: 0.8rem;
                cursor: pointer;

                &:hover {
                    background-color: rgba(#000, 0.02);
                }

                .sec {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .product_name {
                        font-weight: 400;
                        font-size: 100%;
                        max-width: 80%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .product_price {
                        font-weight: 400;
                        font-size: 80%;
                    }

                    .product_extras {
                        font-weight: 300;
                        font-size: 85%;
                        max-width: 50%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .piece {
                        display: flex;
                        flex-direction: row;
                        gap: 0.6rem;
                        align-items: center;

                        .piece-btn {
                            cursor: pointer;
                            padding: 0.1rem;
                            min-width: 1.1rem;
                            border-radius: 50%;
                            background-color: #3cc6b1;
                            color: #fff;
                            border: none;
                        }
                    }
                }
            }
        }

        .payment {
            display: flex;
            flex-direction: column;
            gap: 0.625rem;
            box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
            padding: 0.625rem;
            border-radius: 0.625rem;

            .payment-btn {
                cursor: pointer;
                padding: 0.8rem 7%;
                background-color: #fff;
                color: #000;
                border: none;
                border-radius: 0.625rem;
                width: 100%;

                &.link {
                    background-color: #3cc6b1;
                    color: #fff;
                }
            }
            .payment-btn-disabled {
                cursor: pointer;
                padding: 0.8rem 7%;
                background-color: #5b605f;
                color: #fff;
                border: none;
                border-radius: 0.625rem;
                width: 100%;
            }
        }
    }

    .basket_product_detail {
        background: #f5f3ed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        display: flex;
        z-index: 7000;
        flex-direction: column;
        justify-content: space-between;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .back_icon {
            position: absolute;
            left: 1rem;
            top: 1rem;
            display: flex;
            align-items: center;
            border: none;
            font-size: 160%;
            border-radius: 50%;
            background: #3cc6b1;
            color: #fff;
            padding: 0.3rem;
            cursor: pointer;
        }

        .product_image {
            width: 100%;
            height: auto;
            max-height: 230px;
            background: center;
            object-fit: cover;
        }

        .basket_detail_content {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .detail_title {
                .title {
                    margin-bottom: 0.4rem;
                    font-weight: 400;
                }

                .desc {
                    font-weight: 300;
                    font-size: 90%;
                }
            }

            .ingredients {
                @include ingredients;
            }

            .detail_content {
                .detail_option {
                    font-weight: 400;
                    font-size: 105%;
                    padding-bottom: 0.3rem;
                }

                .detail_extras {
                    list-style: circle;
                    padding-left: 1rem;

                    .detail_extra {
                        font-weight: 300;
                        font-size: 100%;
                    }
                }
            }
        }

        .detail_bottom {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.7rem;

            .product_note {
                font-size: 100%;
                resize: none;
                border: 1px solid #c5c5b0;
                background: #dbdbd1;
                border-radius: 1rem;
                padding: 0.5rem;
                outline: none;

                &:focus {
                    outline: none;
                }
            }

            .basket_detail_end {
                display: flex;
                flex-direction: row;
                gap: 0.7rem;
                padding-top: 0;

                .detail_peace {
                    display: flex;
                    flex-direction: row;
                    gap: 0.6rem;
                    align-items: center;

                    .peace_btn {
                        cursor: pointer;
                        padding: 0.4rem;
                        min-width: 1.3rem;
                        border-radius: 1rem;
                        background-color: #3cc6b1;
                        border: 1px solid #3cc6b1;
                        color: #fff;
                    }
                }

                .save_btn {
                    cursor: pointer;
                    padding: 0.8rem 7%;
                    background-color: #3cc6b1;
                    border: none;
                    color: #fff;
                    border-radius: 0.625rem;
                    width: 100%;
                }
            }
        }
    }
}
.slider_container {
    width: 100%;

    .slider {
        background-size: cover;
        width: 100%;
        height: 350px;
        padding: 11rem 1rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        font-family: "Manrope", sans-serif;

        @include breakpoint(md) {
            padding-top: 8.9rem;
            font-size: 110%;
        }

        @include breakpoint(lg) {
            border-radius: 1rem;
        }

        .title {
            font-weight: 700;
            font-size: 162%;
            color: #fff;
        }

        .subtitle {
            font-weight: 500;
            font-size: 96%;
            color: #fff;
        }

        .restaurant_status {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.3rem;

            .circle_status_info {
                margin-top: 1.5px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                /* background: #17d10e; */
                border: none;
            }
        }
    }
}