@import "./components/Navbar.scss";
@import "./components/SidebarLeft.scss";
@import "./components/Menu.scss";
@import "./components/ProductDetail.scss";

.restaurant_page {
    width: 100%;
    padding: 0;

    @include breakpoint(lg) {
        padding: 0 10%;
    }

    .is_open_alert {
        background: #e16565;
        margin-top: 3px;
        border-radius: 5px;
    }

    .modal_warning {
        background: #3cc6b1;
        margin-top: 3px;
        border-radius: 5px;
        padding: 10px;
    }

    .menu_container {
        display: flex;
        flex-direction: column;

        @include breakpoint(lg) {
            flex-direction: row;
        }
    }
}
