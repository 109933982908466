.main_layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .children_container {
        width: 100%;
        max-width: 1600px;
        min-width: 315px;
    }
}
