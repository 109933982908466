@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");
@import "./base/mixins.scss";

// Components
@import "../components/MainLayout.scss";
@import "../components/Header.scss";
@import "../components/Footer.scss";

// Pages
@import "../pages/CompanyPage/CompanyPage.scss";
@import "../pages/RestaurantPage/RestaurantPage.scss";
@import "../pages/CheckoutPage/CheckoutPage.scss";
@import "../pages/RegisterPage-LoginPage/RegisterPage-LoginPage.scss";
@import "../pages/OrderPage/OrderPage.scss";
@import "../pages/ProfilePage/ProfilePage.scss";
@import "../pages/AddressPage/AddressPage.scss";
@import "../pages/OrderHistoryPage/OrderHistory.scss";
@import "../pages/PointPage/PointPage.scss";
//@import "../pages/PaymentPage/PaymentPage.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #f5f3ed;
    font-family: "Lexend", sans-serif;
    -webkit-tap-highlight-color: transparent;
    font-size: 85%;

    @include breakpoint(md) {
        font-size: 100%;
    }
}

input[type="radio"],
input[type="checkbox"] {
    accent-color: #3cc6b1;
    cursor: pointer;

    @include breakpoint(md) {
        width: 15px;
        height: 15px;
    }
}

.error_sec {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background: #fff;

    .title {
        font-size: 700%;
        color: #444;
        font-weight: 500;
    }

    .sub_title {
        font-weight: 600;
        color: #444;
    }

    .desc {
        font-weight: 300;
        color: #444;
    }
}

.loading_sec {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes loading_container {
    0% {
        transform: translate(12px, 80px) scale(0);
    }
    25% {
        transform: translate(12px, 80px) scale(0);
    }
    50% {
        transform: translate(12px, 80px) scale(1);
    }
    75% {
        transform: translate(80px, 80px) scale(1);
    }
    100% {
        transform: translate(148px, 80px) scale(1);
    }
}
@keyframes loading_container-r {
    0% {
        transform: translate(148px, 80px) scale(1);
    }
    100% {
        transform: translate(148px, 80px) scale(0);
    }
}
@keyframes loading_container-c {
    0% {
        background: #3cc6b1;
    }
    25% {
        background: #3cc6b1;
    }
    50% {
        background: #3cc6b1;
    }
    75% {
        background: #3cc6b1;
    }
    100% {
        background: #3cc6b1;
    }
}
.loading_container .circle {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translate(80px, 80px) scale(1);
    background: #3cc6b1;
    animation: loading_container 3.125s infinite cubic-bezier(0, 0.5, 0.5, 1);
}
.loading_container .circle:nth-child(1) {
    background: #3cc6b1;
    transform: translate(148px, 80px) scale(1);
    animation:
        loading_container-r 0.78125s infinite cubic-bezier(0, 0.5, 0.5, 1),
        loading_container-c 3.125s infinite step-start;
}
.loading_container .circle:nth-child(2) {
    animation-delay: -0.78125s;
    background: #3cc6b1;
}
.loading_container .circle:nth-child(3) {
    animation-delay: -1.5625s;
    background: #3cc6b1;
}
.loading_container .circle:nth-child(4) {
    animation-delay: -2.34375s;
    background: #3cc6b1;
}
.loading_container .circle:nth-child(5) {
    animation-delay: -3.125s;
    background: #3cc6b1;
}
.loading {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #f5f3ed;
}
.loading_container {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.loading_container .circle {
    box-sizing: content-box;
}
