.loginPage {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    .loginPage_container {
        /*  width: 100%;
        max-width: 430px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem; */

        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: relative;
        padding: 0 1rem;
        width: 100%;
        max-width: 398px;

        @include breakpoint(md) {
            max-width: 450px;
        }

        @include breakpoint(xl) {
            max-width: 550px;
        }

        .back_button {
            @include back_button;
        }

        .title {
            text-align: center;
            color: #000;
            font-weight: 600;
        }

        .login {
            @include login_container;

            .context {
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 0.3rem;
                font-size: 90%;

                .compulsory {
                    color: #ff0000;
                }
            }
        }

        .password_info {
            text-align: end;
            text-decoration: none;
            color: #3cc6b1;
            font-weight: 500;
        }

        .login_btn {
            @include submit_button;
        }

        .account_info {
            text-align: center;
            font-size: 110%;

            .account_create {
                text-decoration: none;
                font-weight: 700;
                color: #3cc6b1;
            }
        }
    }
}
