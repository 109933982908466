.pointPage {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 3% 0;

    .pointPage_container {
        @include profile_container;

        .title {
            padding-bottom: 1rem;
            border-bottom: 1px solid #e4e6eb;
            scroll-margin: 6rem;
            color: #000;
            font-weight: 600;
            cursor: default;
        }

        .total_point {
            font-size: 110%;
            cursor: default;
        }

        .price {
            color: #3cc681;
        }

        .point_card_container {
            display: grid;
            grid-template-columns: repeat(1, auto);
            gap: 0.7rem;

            .point_card {
                box-shadow:
                    rgba(0, 0, 0, 0.1) 0 0 5px 0,
                    rgba(0, 0, 0, 0.1) 0 0 1px 0;
                border-radius: 0.3rem;
                transition: 0.2s ease all;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: space-between;
                padding: 0.8rem;
                /* gap: 1rem; */
                row-gap: 0.5rem;
                cursor: default;

                @include breakpoint(md) {
                    flex-direction: row;
                }

                .point_content {
                    font-weight: 300;
                    font-size: 105%;
                }
            }
        }
    }
}
