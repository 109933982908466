.OrderPage {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2%;
    

    .title {
        font-weight: 400;

        @include breakpoint(md) {
            font-size: 500%;
        }
    }

    .order_status_payment_button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-radius: 1rem;
        gap: 0.4rem;
        font-size: 90%;
        font-weight: 900;
        color: #fff;
        /* background: #ffc439; */
        background: #3cc6b1;
        border: none;
        cursor: pointer;

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
        margin-top: 5px;
    }

    .order_status_container {
        width: 80%;
        display: flex;
        flex-direction: column;
        background: #fff;
        padding: 1.5rem;
        border: 1px solid rgba(128, 128, 128, 0.292);
    }

    .order_status_top_ege {
        width: 100%;
    }

    .order_status_details_ege {
        margin-top: 40px;
        flex-wrap: wrap;
        border: 1px solid rgba(128, 128, 128, 0.292);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }

    .order_status_details_item_ege {
        width: 20%;
    }

    @media screen and (max-width: 968px) {
        .order_status_details_item_ege {
            width: 45%;
            margin-top: 10px;
        }
        .order_status_details_ege {
            justify-content: center;
            gap: 25px;
        }
    }

    @media screen and (max-width: 968px) {
        .order_status_details_item_ege {
            width: 100%;
            margin-top: 10px;
        }
        .order_status_details_ege {
            flex-direction: column;
        }
    }

    .order_status_bars_ege {
        margin-top: 50px;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .order_status_bar_line_ege {
        position: absolute;
        top: 30%;
        width: 100%;
        height: 5px;
        background-color: #e9e9e9;
    }

    .order_status_bar_line_inner_ege {
        width: 33%;
        height: 100%;
        background-color: #3cc6b1;
    }

    .order_status_bar_line_inner_ege.cancelled {
        background-color: #e06666;
    }

    .order_status_bar_ege {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .order_status_bar_circle_ege {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #e9e9e9;
        margin-bottom: 10px;
        z-index: 50;
        position: relative;
    }

    .order_status_bar_circle_ege.active {
        background-color: #3cc6b1;
    }

    .order_status_bar_circle_ege.cancelled {
        background-color: #e06666;
        
    }
    
    .order_status_inner_ping_ege {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 50%;
        animation: ping 1.2s cubic-bezier(0, 0, 0.2, 1) infinite;
        background-color: #e06666;
    }

    .loader {
        width: 24px;
        height: 24px;
        border: 2px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        }
    
        @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        } 


    @keyframes ping {
        75%, 100% {
            width: 125%;
            height: 125%;
            opacity: 0;
        }
    }

    @media screen and (max-width: 600px) {
        .order_status_bar_circle_ege {
            width: 32px;
            height: 32px;
        }

        .order_status_bar_line_ege {
            height: 2px;
        }

        .order_status_bar_ege p {
            font-size: 12px;
        }
    }

    .order_status_texts_ege {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        gap: 20px;
    }

    .order_status_text_ege {
        padding: 16px 24px;
        background-color: #e0666614;
        border-radius: 20px;
        display: flex;
        align-items: center;
        gap: 50px;
    }

    .order_status_payment_button {
        width: 200px;
        padding: 10px;
        border-radius: 10px;
        background-color: #f85e5ed4;
        outline: none;
        border: none;
        color: white;
        cursor: pointer;
        transition: .3s;
        font-weight: bold;
    }

    .order_status_payment_button:hover {
        background-color: #f85e5e;
    }

    .order_status_products_ege {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .order_status_product_ege {
        padding: 16px 24px;
        background-color: #66b1e014;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
    }

    .order_status_product_title_ege {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .order_status_top_ege {
        display: flex;
    }

    .order_status_prices_ege {
        display: flex;
        flex-direction: column;
        align-items: end;
        margin-top: 40px;
    }

    .order_status_prices_ege div {
        display: flex;
        justify-content: space-between;
        width: 30%;
    }

    @media screen and (max-width: 968px) {
        .order_status_prices_ege div {
            width: 70%;
        }
    }
}
