@import "../styles/base/mixins.scss";

.footer {
    background: linear-gradient(0deg, rgba(#8f8f8f, 0.1) 70%, rgba(245, 243, 237, 0.5) 100%);
    min-width: 315px;
    padding: 2rem 0.5rem;
    display: flex;
    width: 100%;

    @include breakpoint(lg) {
        padding: 3rem 10%;
    }

    .footer_content {
        width: 100%;
        max-width: 1600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin: auto;
        row-gap: 1rem;

        @include breakpoint(md) {
            flex-direction: row;
        }

        .footer_item {
            text-decoration: none;
            color: #000;
            cursor: pointer;
        }
    }
}
