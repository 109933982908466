@import "../../styles/base/mixins.scss";
.CheckoutPage-container {
    width: 100%;
    height: 100%;
    padding: 3% 0;
    background: #fff;
    min-height: 100vh;

    .checkout-container {
        margin: auto;
        display: flex;
        width: 100%;
        position: relative;
        flex-direction: column;
        justify-content: center;
        row-gap: 1rem;

        @include breakpoint(lg) {
            flex-direction: row;
        }

        .checkout {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            position: relative;
            padding: 0 1rem;

            @include breakpoint(md) {
                padding: 0 5%;
            }

            @include breakpoint(lg) {
                padding: 0;
                width: 40%;
                max-width: 40rem;
            }

            .back_button {
                @include back_button;
            }

            .title {
                padding-bottom: 1rem;
                border-bottom: 1px solid #e4e6eb;
                scroll-margin: 6rem;
                color: #000;
                font-weight: 600;
            }

            .content {
                display: flex;
                flex-direction: column;
                gap: 1.8rem;

                .content-title {
                    font-weight: 500;
                    padding-bottom: 0.7rem;
                }

                .title-container {
                    border-bottom: 1px solid #e4e6eb;
                    padding-bottom: 0.7rem;

                    .subtitle {
                        font-weight: 300;
                        font-size: 90%;
                    }
                }

                .sec {
                    display: flex;
                    flex-direction: column;
                    gap: 0.3rem;

                    .btn-container {
                        width: 100%;
                        display: flex;
                        flex-direction: row;

                        .detail-btn {
                            padding: 0.5rem 0;
                            width: 50%;
                            border-bottom-left-radius: 0.3rem;
                            border-top-left-radius: 0.3rem;
                            background: transparent;
                            border: 1px solid #8d8c88;
                            font-weight: 600;
                            cursor: default;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 0.3rem;

                            &:last-child {
                                border-radius: 0 0.3rem 0.3rem 0;
                            }

                            &.passive {
                                opacity: 0.3;
                                cursor: pointer;
                            }
                        }
                    }

                    .clock_container {
                        display: flex;
                        flex-direction: row;
                        gap: 0.7rem;
                        align-items: center;
                        position: relative;
                    }

                    .details-form {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        .form-item {
                            display: flex;
                            flex-direction: column;
                            gap: 0.2rem;
                            justify-content: center;
                            position: relative;

                            .form-label {
                                font-size: 85%;
                                font-weight: 400;

                                .compulsory {
                                    color: #ff0000;
                                }
                            }

                            .form_txt_container {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .error {
                                    font-size: 75%;
                                    font-weight: 300;
                                    color: #ff0000;
                                }
                            }

                            .form-txt {
                                border: 1px solid #e4e6eb;
                                padding: 0.6rem;
                                border-radius: 0.2rem;
                                background: #fdfdfd;
                                width: 100%;

                                &::placeholder {
                                    color: #8d8c88;
                                }

                                &:focus {
                                    outline: none;
                                }
                            }

                            .address_container {
                                width: 100%;
                                position: relative;
                                display: flex;
                                align-items: center;

                                .validate_info {
                                    position: absolute;
                                    z-index: 7000;
                                    right: 0.5rem;
                                }
                            }

                            .suggestions {
                                list-style: none;
                                position: absolute;
                                width: 99.9%;
                                border: 1px solid #e4e6eb;
                                border-radius: 0.2rem;
                                z-index: 1999;
                                top: 3.28rem;

                                @include breakpoint(md) {
                                    top: 3.45rem;
                                }

                                .suggestion_item {
                                    @include suggestion_item;
                                }
                            }
                        }
                    }

                    .product-list {
                        padding: 0 1rem;
                        border-left: 1px solid #e4e6eb;
                        margin-bottom: 0.8rem;
                        list-style: none;
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        .product-list-item {
                            font-weight: 500;
                            display: flex;
                            flex-direction: column;
                            gap: 0.3rem;
                            font-size: 90%;
                            border-bottom: 1px solid #e4e6eb;
                            padding-bottom: 1rem;

                            &:last-child {
                                border-bottom: none;
                                padding-bottom: 0.4rem;
                            }

                            .product_option {
                                font-size: 90%;
                                padding: 0 1rem;
                                display: flex;
                                flex-direction: column;
                                gap: 0.3rem;

                                p {
                                    font-size: 90%;
                                    .note {
                                        font-weight: 300;
                                    }
                                }

                                .product_extra {
                                    font-weight: 300;
                                    font-size: 90%;
                                }
                            }
                        }
                    }

                    .product-message-box {
                        padding: 1rem;
                        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                        position: relative;
                        cursor: pointer;

                        .message-title-container {
                            display: flex;
                            flex-direction: row;
                            gap: 0.5rem;
                            align-items: center;
                        }

                        .title-content {
                            margin-left: 1.5rem;
                        }

                        .title-content {
                            font-size: 80%;
                            font-weight: 400;
                            color: #a7aab7;
                        }

                        .arrow-icon {
                            position: absolute;
                            right: 1rem;
                            top: 40%;
                            cursor: pointer;
                        }
                    }

                    .restaurant_note {
                        resize: none;
                        border: 1px solid #c5c5b0;
                        background: #fff;
                        padding: 0.5rem;
                        outline: none;
                        margin-top: -0.3rem;

                        &:focus {
                            outline: none;
                        }
                    }

                    .coupon_content {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0.7rem;

                        .coupon_txt {
                            border: 1px solid #e4e6eb;
                            padding: 0.6rem;
                            border-radius: 0.5rem;
                            background: #fdfdfd;
                            width: 100%;

                            &::placeholder {
                                color: #8d8c88;
                            }

                            &:focus {
                                outline: none;
                            }
                        }

                        .valid_btn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0.6rem;
                            border-radius: 0.5rem;
                            color: #fff;
                            /* background: #ffc439; */
                            background: #3cc6b1;
                            border: none;
                            cursor: pointer;

                            &.disabled {
                                opacity: 0.5;
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }
        }

        .payment-card {
            padding: 1rem;
            box-shadow:
                rgba(14, 30, 37, 0.12) 0 2px 4px 0,
                rgba(14, 30, 37, 0.32) 0 2px 16px 0;
            border-radius: 0.5rem;
            background: #fff;
            margin: 0 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: start;
            height: fit-content;

            @include breakpoint(min) {
                margin: 0.5rem 1rem 2rem 0.5rem;
            }

            @include breakpoint(md) {
                margin: 0 5%;
            }

            @include breakpoint(lg) {
                padding: 2rem;
                margin: 2rem 0 0 4rem;
                min-width: 325px;
                max-width: 360px;
                width: 45%;
            }

            a {
                text-decoration: none;
            }

            .price-info {
                display: flex;
                flex-direction: column;
                gap: 0.8rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid #e4e6eb;

                .info-title {
                    font-size: 130%;
                    font-weight: 600;
                }

                .product-list {
                    display: flex;
                    flex-direction: column;
                    gap: 0.2rem;

                    .list-item {
                        font-size: 90%;
                        font-weight: 300;
                        display: flex;
                        justify-content: space-between;
                        gap: 1rem;
                    }
                }

                .total-price {
                    font-size: 90%;
                    font-weight: 500;
                    display: flex;
                    justify-content: space-between;
                    gap: 1rem;
                }
            }

            .conditions {
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
                font-size: 80%;
                border-bottom: 1px solid #e4e6eb;
                padding-bottom: 1rem;
                cursor: pointer;
                align-items: center;
            }

            .pay-button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                border-radius: 0.5rem;
                gap: 0.4rem;
                font-size: 80%;
                font-weight: 900;
                color: #fff;
                background: #5a78ff;
                border: none;
                cursor: pointer;

                .pay-icon {
                    font-size: 1.2rem;
                }

                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }

            .pay-credit-title {
                color: #8d8c88;
                font-size: 0.8rem;
                text-align: center;
                position: relative;

                &:before,
                &:after {
                    position: absolute;
                    top: 50%;
                    overflow: hidden;
                    width: 25%;
                    height: 1px;
                    content: "\a0";
                    background-color: #e4e6eb;
                    margin-left: 2%;
                }

                &:before {
                    margin-left: -25%;
                    text-align: right;
                }
            }

            .credit-pay-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                border-radius: 1.5rem;
                gap: 0.4rem;
                font-size: 90%;
                font-weight: 900;
                color: #fff;
                background: #231f20;
                border: none;
                cursor: pointer;

                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }

            .cash_btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                border-radius: 1.5rem;
                gap: 0.4rem;
                font-size: 90%;
                font-weight: 900;
                color: #fff;
                /* background: #ffc439; */
                background: #3cc6b1;
                border: none;
                cursor: pointer;

                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
    }
}
