.profilePage {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 3% 0;

    .profilePage_container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: relative;
        padding: 0 1rem;
        width: 100%;
        max-width: 398px;

        @include breakpoint(md) {
            max-width: 450px;
        }

        @include breakpoint(xl) {
            max-width: 550px;
        }

        .back_button {
            @include back_button;
        }

        .title {
            padding-bottom: 1rem;
            border-bottom: 1px solid #e4e6eb;
            scroll-margin: 6rem;
            color: #000;
            font-weight: 600;
        }

        .profilePhoto_sec {
            display: flex;
            justify-content: center;

            .profile_image {
                width: 90px;
                height: 90px;
                border-radius: 50%;
                object-fit: cover;

                @include breakpoint(md) {
                    width: 100px;
                    height: 100px;
                }

                @include breakpoint(xl) {
                    width: 120px;
                    height: 120px;
                }
            }
        }

        .login {
            @include login_container;

            .login_btn {
                @include submit_button;
            }
        }
    }
}
