@mixin breakpoint($point) {
    @if $point == md {
        // 768px
        @media (min-width: 48em) {
            @content;
        }
    }
    @if $point == lg {
        // 1024px
        @media (min-width: 64em) {
            @content;
        }
    }
    @if $point == xl {
        // 1440px
        @media (min-width: 90em) {
            @content;
        }
    }
    @if $point == min {
        // 475
        @media (min-width: 400px) {
            @content;
        }
    }
}

@mixin back_button {
    font-size: 120%;
    font-weight: 900;
    position: absolute;
    background-color: #3cc6b1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 6000;
    color: #fff;
    top: 0.3rem;
    right: 3%;

    @include breakpoint(md) {
        right: 5%;
    }
    @include breakpoint(lg) {
        right: 1%;
    }
}

@mixin profile_container {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    position: relative;
    padding: 0 1rem;
    width: 100%;
    max-width: 398px;

    @include breakpoint(md) {
        max-width: 450px;
    }

    @include breakpoint(xl) {
        max-width: 550px;
    }

    .back_button {
        @include back_button;
    }

    .empty {
        text-align: center;
        width: 100%;
        font-weight: 300;
    }
}

@mixin suggestion_item {
    padding: 0.4rem 0.6rem;
    background: #fff;
    font-size: 80%;
    cursor: pointer;
    border-bottom: 1px solid #e4e6eb;
    color: #000;
    font-weight: 300;
    transition: 0.1s ease;

    &:hover {
        background: #5a78ff;
        color: #fff;
    }
}

@mixin ingredients {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;

    .title {
        font-family: "Manrope", sans-serif;
        font-weight: 300;
    }

    .ingredient {
        font-family: "Manrope", sans-serif;
        font-size: 83%;
        font-weight: 500;
        padding: 0.15rem 0.5rem;
        border-radius: 1rem;
        /* color: white;
        background-color: rgba(#059862, 0.7); */
        color: #059862;
        border: 1px solid #059862;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.6px;
        cursor: pointer;
        transition: 0.3s ease all;

        &:hover {
            background-color: #059862;
            color: #fff;
        }

        &.passive {
            border: 1px solid #de0a0a;
            /* background: rgba(#de0a0a, 0.7); */
            color: #de0a0a;

            &:hover {
                background-color: #de0a0a;
                color: #fff;
            }
        }
    }
}

@mixin submit_button {
    padding: 0.9rem;
    color: #fff;
    border: none;
    border-radius: 2rem;
    background: #3cc6b1;
    font-size: 100%;
    font-weight: 600;
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

@mixin close_button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    align-items: center;
    border: none;
    font-size: 120%;
    border-radius: 50%;
    background: #3cc6b1;
    color: #fff;
    padding: 0.3rem;
    cursor: pointer;
}

@mixin login_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .form_item {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .form_txt_container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .error {
                font-size: 75%;
                font-weight: 300;
                color: #ff0000;
            }
        }

        .input_valid_sec {
            display: flex;
            flex-direction: row;
            gap: 0.7rem;
        }

        .compulsory {
            color: #ff0000;
        }

        .textbox {
            font-size: 100%;
            border: 1px solid #e4e6eb;
            padding: 0.6rem;
            border-radius: 0.2rem;
            background: #fdfdfd;
            width: 100%;

            &::placeholder {
                color: #8d8c88;
            }

            &:focus {
                outline: none;
            }
        }
    }
}