@import "../styles/base/mixins.scss";

.header {
    width: 100%;
    background-color: #f5f3ed;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    gap: 0.8rem;
    padding: 0.4rem 1rem;

    @include breakpoint(lg) {
        padding: 0.5rem 10%;
    }

    .logo {
        cursor: default;
        width: auto;
        height: 45px;
    }

    .nav_items {
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        align-items: center;
        justify-content: center;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 130%;
        }

        @include breakpoint(lg) {
            gap: 1rem;
        }

        .user_container {
            display: flex;
            flex-direction: row;
            gap: 0.8rem;
        }

        .nav_item {
            color: #000;
            background: transparent;
            font-size: 90%;
            font-weight: 700;
            border: none;
            border-radius: 0.5rem;
            cursor: pointer;
            font-family: "Manrope", sans-serif;
        }

        .dropdown_account {
            position: relative;

            .profile_image {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                cursor: pointer;
                object-fit: cover;
                border: 1px solid rgba(#000, 0.4);
            }

            .dropdown_toggle_list {
                min-width: 117px;
                list-style: none;
                position: absolute;
                background: #f5f3ed;
                /* border: 1px solid rgba(#000, 0.4); */
                border-radius: 0.4rem;
                left: -80px;
                box-shadow:
                    rgba(50, 50, 93, 0.25) 0 50px 100px -20px,
                    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
                    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

                @include breakpoint(md) {
                    left: -90px;
                }

                .list_item {
                    /*  padding: 0.7rem; */
                    border-bottom: 1px solid rgba(#000, 0.1);
                    box-shadow: rgba(17, 17, 26, 0.1) 0 0 16px;
                    cursor: pointer;
                    transition: 0.2s ease all;
                    font-weight: 400;
                    font-size: 90%;
                    color: #3a3938;
                    display: flex;

                    .item_link {
                        text-decoration: none;
                        padding: 0.7rem;
                        white-space: nowrap;
                        font-weight: 400;
                        color: #3a3938;
                        width: 100%;
                    }

                    &:hover {
                        background-color: rgba(#000, 0.05);
                    }

                    &:last-child {
                        border-bottom: none;
                        padding: 0.7rem;

                        &:hover {
                            background-color: rgba(#ff0000, 0.05);
                        }
                    }
                }
            }
        }
    }
}

.header-toggleBar {
    background-color: #f5f3ed;
    padding: 2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    z-index: 6000;
    max-width: 350px;
    width: 100%;

    &.active {
        display: none;
    }

    .setting_title {
        text-align: center;
        margin-top: 7%;
        font-weight: 500;
    }

    .close-icon {
        font-size: 130%;
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
    }

    .toggleBar-content {
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-bottom: 1px solid #8d8c88;

        .toggleBar-item-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 4rem;

            &.passive {
                opacity: 0.5;
                text-decoration: line-through;
            }

            &.active {
                font-weight: 700;

                .hour {
                    font-weight: 500;
                }
            }

            .hour {
                font-weight: 200;
            }
        }
    }

    .toggleBar-info {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .toggleBar-info-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            .info {
                text-decoration: none;
                color: #0891b2;
            }
        }
    }
}
