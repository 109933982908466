@import "../../../styles/base/mixins.scss";

.menu {
    width: 100%;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include breakpoint(lg) {
        width: 75%;
    }
    .original_price {
        color: gray;
        text-decoration: line-through;
    }

    .menu_category_item {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .category_collapse {
            background: #F5F3ED;
            padding: 0;
            font-size: 16px;
            margin: -16px;
            box-shadow: 0 0 0 0 #241f31;
        }
    }

    .category_title {
        border-bottom: 1px solid #c5c5b0;

        .title {
            color: #4b643e;
            position: relative;
            cursor: default;
            font-family: "Manrope", sans-serif;
            font-weight: 900;
        }

        .open_hours {
            font-weight: 500;
            padding-top: 0.4rem;
            padding-bottom: 0.5rem;
        }

        .title_desc {
            font-size: 85%;
            font-weight: 300;
            padding-top: 0.4rem;
            padding-bottom: 0.5rem;
            font-style: italic;
        }
    }

    .products_container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;

        .product_box {
            /* height: 170px; */
            height: auto;
            box-shadow: rgba(17, 17, 26, 0.1) 0 0 16px;
            padding: 1rem;
            border-radius: 1rem;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: start;
            transition: 0.3s ease;

            @include breakpoint(md) {
                box-shadow: rgba(99, 99, 99, 0.1) 0 2px 8px 0;
                height: 200px;
            }

            &:hover {
                background-color: rgba(#000, 0.02);
            }

            .product_box_content {
                padding-right: 0.7rem;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                row-gap: 0.5rem;

                .product_title {
                    font-family: "Lexend", sans-serif;
                    font-weight: 500;
                }

                .product_desc {
                    font-weight: 200;
                    font-family: "Manrope", sans-serif;
                    font-size: 90%;
                }
                .product_price {
                    font-family: "Lexend", sans-serif;
                    font-weight: 600;
                    font-size: 100%;
                    color: #4b643e;
                }
            }

            .product_img {
                height: auto;
                width: 40%;
                border-radius: 1rem;
                object-fit: contain;
                text-align: center;

                @include breakpoint(md) {
                    height: auto;
                    width: 30%;
                    max-width: 210px;
                    min-width: 200px;
                }
            }
        }
    }
}
