.orderHistoryPage {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 3% 0;

    .OrderHistoryPage_container {
        @include profile_container;

        .title {
            padding-bottom: 1rem;
            border-bottom: 1px solid #e4e6eb;
            scroll-margin: 6rem;
            color: #000;
            font-weight: 600;
        }

        .order_card_container {
            display: grid;
            grid-template-columns: repeat(1, auto);
            gap: 1rem;

            .order_card {
                box-shadow:
                    rgba(0, 0, 0, 0.1) 0 0 5px 0,
                    rgba(0, 0, 0, 0.1) 0 0 1px 0;
                border-radius: 0.3rem;
                transition: 0.2s ease all;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 0.8rem;
                /* gap: 1rem; */
                row-gap: 0.5rem;
                cursor: pointer;
                color: #000;
                text-decoration: none;

                @include breakpoint(md) {
                    flex-direction: row;
                }

                &:hover {
                    background-color: rgba(#000, 0.02);
                }

                .content_sec {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .content_title {
                        font-weight: 300;
                    }

                    .content {
                        font-weight: 200;
                        font-size: 90%;
                    }

                    .repeat_btn {
                        width: 100%;
                        height: 100%;
                        border: none;

                        padding: 0.7rem 0.4rem;
                        color: #fff;
                        border-radius: 0.5rem;
                        background: #3cc6b1;
                        /* font-size: 100%; */
                        font-weight: 500;
                        cursor: pointer;

                        &.disabled {
                            opacity: 0.5;
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
}
