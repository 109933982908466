.addressPage {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 3% 0;

    .addressPage_container {
        @include profile_container;

        .edit_sec {
            display: flex;
            justify-content: space-between;
            align-items: end;
            padding-bottom: 1rem;
            border-bottom: 1px solid #e4e6eb;

            .title {
                color: #000;
                font-weight: 600;
                cursor: default;
            }

            .add_address {
                background-color: transparent;
                padding: 0.3rem 0.6rem;
                font-size: 105%;
                border: 1px solid #e4e6eb;
                cursor: pointer;
                transition: 0.2s ease all;
                border-radius: 0.2rem;
                margin-right: 2.5rem;

                &:hover {
                    background-color: rgba(#000, 0.05);
                }
            }
        }

        .address_card_container {
            display: grid;
            grid-template-columns: repeat(1, auto);
            gap: 1rem;

            .address_card {
                border-radius: 0.3rem;
                cursor: pointer;
                transition: 0.2s ease all;
                box-shadow:
                    rgba(0, 0, 0, 0.1) 0 0 5px 0,
                    rgba(0, 0, 0, 0.1) 0 0 1px 0;

                &:hover {
                    background-color: rgba(#000, 0.02);
                }

                .address_content {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    align-items: center;
                    padding: 1rem;

                    .ticked {
                        &.true {
                            color: #008000;
                        }
                        &.false {
                            color: #ff0000;
                        }
                    }
                }
            }
        }
    }

    .add_detail_container {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: fixed;
        display: flex;
        z-index: 6000;
        overflow-y: scroll;

        .add_detail {
            position: relative;
            background-color: #fff;
            border-radius: 0.625rem;
            width: 96%;
            max-width: 409px;
            margin: auto;
            display: flex;
            flex-direction: column;
            z-index: 9000;
            overflow: hidden;

            @include breakpoint(md) {
                width: 54%;
                max-width: 450px;
            }

            .close_btn {
                @include close_button;
            }

            .login {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 2.5rem;

                .form_item {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                }

                .form_txt_container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .error {
                        font-size: 75%;
                        font-weight: 300;
                        color: #ff0000;
                    }
                }

                .form-txt {
                    border: 1px solid #e4e6eb;
                    padding: 0.6rem;
                    border-radius: 0.2rem;
                    background: #fdfdfd;
                    width: 100%;
                    font-size: 100%;

                    &:focus {
                        outline: none;
                    }
                }

                .address_container {
                    width: 100%;
                    position: relative;
                    display: flex;
                    align-items: center;

                    .validate_info {
                        position: absolute;
                        z-index: 7000;
                        right: 0.5rem;
                    }
                }

                .form-item {
                    position: relative;
                }

                .suggestions {
                    list-style: none;
                    position: absolute;
                    width: 99.9%;
                    border: 1px solid #e4e6eb;
                    border-radius: 0.2rem;
                    z-index: 1999;
                    top: 3.28rem;

                    @include breakpoint(md) {
                        top: 3.68rem;
                    }

                    .suggestion_item {
                        @include suggestion_item;
                    }
                }

                .compulsory {
                    color: #ff0000;
                }

                .textbox {
                    /* height: 50px;
                    background-color: transparent;
                    border: 1px solid rgba(#000, 0.1);
                    padding: 0 0.5rem;
                    border-radius: 0.5rem; */
                    font-size: 100%;

                    border: 1px solid #e4e6eb;
                    padding: 0.6rem;
                    border-radius: 0.5rem;
                    background: #fdfdfd;
                    width: 100%;

                    &::placeholder {
                        color: #8d8c88;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                .login_btn {
                    padding: 0.9rem;
                    color: #fff;
                    border: none;
                    border-radius: 2rem;
                    background: #3cc6b1;
                    font-size: 100%;
                    font-weight: 600;
                    cursor: pointer;

                    &.disabled {
                        opacity: 0.5;
                        cursor: not-allowed;
                    }

                    &.red {
                        background: #ef2e2e;
                    }
                }

                .context {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    gap: 0.3rem;
                    font-size: 90%;

                    .compulsory {
                        color: #ff0000;
                    }
                }
            }
        }
    }
}
