.sidebar_left {
    background-color: #f5f3ed;
    width: 100%;
    position: sticky;
    height: fit-content;
    padding: 1rem;
    top: 4.4rem;
    border-bottom: 1px solid #ddd;
    z-index: 2000;

    @include breakpoint(lg) {
        width: 25%;
        border-bottom: none;
        border-right: 1px solid #ddd;
        top: 4.71rem;
    }

    .sidebar_items {
        .sidebar_items_list {
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            text-align: center;
            row-gap: 0.8rem;
            column-gap: 1.2rem;
            overflow-x: scroll;
            text-transform: capitalize;
            &::-webkit-scrollbar {
                display: none;
            }

            @include breakpoint(md) {
                justify-content: space-around;
            }

            @include breakpoint(lg) {
                flex-direction: column;
                align-items: start;
                text-align: start;
                overflow-x: hidden;
            }

            .list_item {
                text-decoration: none;

                .list_item_btn {
                    border: none;
                    font-size: 100%;
                    color: #62615f;
                    cursor: pointer;
                    white-space: nowrap;
                    font-weight: 300;
                    text-transform: capitalize;
                    font-family: "Manrope", sans-serif;
                    background-color: transparent;

                    &.active {
                        color: #4b643e;
                        font-weight: 500;
                    }

                    @include breakpoint(lg) {
                        white-space: inherit;
                    }
                }
            }
        }
    }
}
