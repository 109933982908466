@import "../../styles/base/mixins.scss";

.company_page {
    background-size: cover;
    width: 100%;
    padding: 5% 10%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    .company_title {
        text-align: center;
        color: #fff;
        font-weight: 500;
    }

    .restaurant_box_container {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(1, minmax(230px, 500px));
        gap: 1rem;

        @include breakpoint(md) {
            grid-template-columns: repeat(3, minmax(230px, 270px));
        }

        @include breakpoint(lg) {
            grid-template-columns: repeat(4, minmax(230px, 270px));
        }

        .restaurant_box {
            height: 200px;
            box-shadow:
                rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
                rgba(138, 98, 98, 0.06) 0px 2px 4px -1px;
            background-color: rgba(#fff, 0.9);
            border-radius: 0.5rem;
            text-decoration: none;
            cursor: pointer;
            padding: 0 2.3rem;
            transition: 0.3s ease;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            text-align: center;

            &:hover {
                filter: brightness(0.9);
                -webkit-transform: scale(1.05);
                -ms-transform: scale(1.05);
                -moz-transform: scale(1.05);
                transform: scale(1.05);
            }
            .box_title {
                color: #000;
                font-weight: 500;
            }

            .box_desc {
                color: #000;
                font-weight: 300;
                font-size: 1rem;
            }

            .box_btn {
                color: #fff;
                background: #075985;
                font-size: 1.1rem;
                border-radius: 1.5rem;
                border: none;
                padding: 0.7rem 20%;
                cursor: pointer;
            }
        }
    }
}
